.nav {
  color: white;
}

.explore-imgtitle-style {
  background: #ebebeb;
  border-radius: 0;
  border: none;
}

.explore-image-resize {
  width: 80%;
}

.explore-card-title {
  font-size: 18px;
  font-weight: bold;
}

/*Theme Page*/
.theme-card-title {
  font-size: 15px;
  font-weight: bold;
}

.card-text {
  font-size: 13px;
}

.theme-card-style {
  background: #cecdcd;
  border-radius: 0;
  border: none;
}

.theme-card-style-small {
  background: #cecdcd;
  border-radius: 0;
  border: none;
}

.movie-title {
  font-size: 14px;
  font-weight: bold;
}

.movie-card-text {
  font-size: 13px;
}

.movie-director {
  font-size: 13px;
}

.card-style {
  background: #cecdcd;
  border-radius: 0;
  border: none;
}

.card-style:hover {
  background: #343434;
  color: white;
  border-radius: 0;
  border: none;
}

.card-style-blue-text {
  background: #cecdcd;
  border-radius: 0;
  border: none;
  color: #2f7da6;
}

.card-style-blue-text:hover {
  background: #343434;
  border-radius: 0;
  border: none;
  color: white;
}

.heading-style {
  background: #ebebeb;
}

body {
  color: #282828;
  font-family: Helvetica, Arial, Verdana, sans-serif;
}

.breadcrumb {
  background-color: #ebebeb;
  border-radius: 0;
}

.intro-card {
  background-color: #ebebeb;
  border-radius: 0;
  font-size: 13px;
}

.custom-nav {
  background-color: #525252;
}

.link {
  color: #2f7da6 !important;
}

.link:hover {
  color: #335870 !important;
}

.zero-padding {
  padding-left: 0;
  padding-right: 0;
}

.zero-margin {
  margin-bottom: 0 !important;
}

.breadcrumb > .active {
  color: #6c757d;
}

.breadcrumb li a {
  color: #2f7da6;
}

.breadcrumb li a:hover {
  color: #335870;
}

@media (max-width: 991.99px) {
  .theme-card-style {
    display: none;
  }
}

@media (min-width: 992px) {
  .theme-card-style-small {
    display: none;
  }
}
